<template>
    <div class="col-12 bg-white br-b-8 px-0">
        <el-table
        :data="pedidos"
        header-row-class-name="f-16 text-general f-500"
        style="width: 100%"
        @selection-change="setSeleccion"
        >
            <!-- Tab seleccion -->
            <el-table-column
            v-if="(selection && pedidos.length)"
            fixed
            type="selection"
            class-name="check-red"
            width="50"
            />
            <!-- Tab seleccion -->
            <el-table-column fixed prop="id" label="Pedido" width="100" />
            <el-table-column :label="$config.vendedor" class-name="text-center" min-width="180">
                <template slot-scope="{row}">
                    <div class="d-middle-center">
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:280px;">
                                Cliente: {{ row.cliente }} <br />
                                ID: {{ row.id_pedido }}
                            </div>
                            <div class="text-center" style="width:30px;">
                                <i v-show="row.cliente_entrega_directa" class="icon-cliente" />
                            </div>
                        </el-tooltip>
                        <p class="col px-0 text-left">
                            {{ row.lechero }}
                        </p>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="cedis" class-name="text-center" label="Cedis" min-width="120" />

            <el-table-column label="Valor Pedido" min-width="180" class-name="text-center">
                <template slot-scope="{row}">
                    <p>
                        {{ convertMoney(row.total, row.idm_moneda) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha Entrega" class-name="text-center" min-width="180">
                <template slot-scope="{row}">
                    <p>
                        {{ row.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column class-name="text-center" label="Alianza Comisión" min-width="180">
                <template slot-scope="scope">
                    <div class="row mx-0">
                        <div class="col-12 px-0">
                            <template v-if="sinprocesar">
                                <p>-</p>
                            </template>
                            <template v-if="!sinprocesar">
                                <el-select v-model="scope.row.id_condicion" size="small" class="w-100" :disabled="(aplicados || descartados) && !$can('botones_tesoreria_deliverys') " @change="cambioComision(scope)">
                                    <el-option
                                    v-for="item in funComisiones(scope.row.id_cedis)"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                            </template>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Comisión" class-name="text-center" min-width="100">
                <template slot-scope="{row}">
                    <p v-if="sinprocesar">
                        -
                    </p>
                    <p v-if="!sinprocesar">
                        {{ convertMoney(row.valor_condicion, row.idm_moneda) }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column class-name="text-center" label="Pago comisión" min-width="150">
                <template slot-scope="{row}">
                    <div class="row mx-0">
                        <div class="col-12 px-0">
                            <template v-if="sinprocesar">
                                <p>-</p>
                            </template>
                            <template v-if="!sinprocesar">
                                <el-select v-model="row.estado_comision" size="small" class="w-100" :disabled="([12,22,31].includes(row.tipo_pago) || aplicados || descartados) && !$can('botones_tesoreria_deliverys')">
                                    <el-option label="No aplica comisión" :value="1" />
                                    <el-option label="Ha sido Cobrada" :value="2" />
                                    <el-option label="Sera Cobrada" :value="3" />
                                    <el-option label="Pendiente" :value="4" />
                                </el-select>
                            </template>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column v-if="columCredito" class-name="text-center" label="Credito" min-width="100">
                <template slot-scope="{row}">
                    <div class="row mx-0">
                        <div class="col-12 px-0">
                            <el-select v-model="row.dias_credito" :disabled="!$can('botones_tesoreria_deliverys')" size="small" class="w-100">
                                <el-option
                                v-for="i in 15"
                                :key="i"
                                :label="i + ' días'"
                                :value="i"
                                />
                            </el-select>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Dinero reportado" class-name="text-center" min-width="180">
                <template slot-scope="{row}">
                    <p v-if="sinprocesar">
                        -
                    </p>
                    <p v-if="!sinprocesar">
                        {{ convertMoney(row.pago_dinero, row.idm_moneda) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Dinero efectivo" class-name="text-center" min-width="150">
                <template slot-scope="{row}">
                    <p v-if="sinprocesar">
                        -
                    </p>
                    <p v-if="!sinprocesar">
                        {{ convertMoney(row.valor_final, row.idm_moneda) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Dinero comisión" class-name="text-center" min-width="150">
                <template slot-scope="{row}">
                    <p v-if="sinprocesar">
                        -
                    </p>
                    <p v-if="!sinprocesar">
                        {{ convertMoney(row.valor_condicion, row.idm_moneda) }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column v-if="efectivo || transferencia" :label="tipoCaja == 1 ? 'Caja' : 'Cuenta'" min-width="150">
                <template slot-scope="{row}">
                    <div class="row mx-0">
                        <div class="col-12 px-0">
                            <el-select v-model="row.id_caja" :disabled="!$can('botones_tesoreria_deliverys')" size="small" class="w-100">
                                <el-option
                                v-for="item in funCajas(row.id_cedis, tipoCaja)"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-if="aplicados" prop="caja_nombre" label="Caja / Cuenta" min-width="150" />
        </el-table>
    </div>
</template>
<script>
import PedidosAdmin from '~/services/pedidos/pedidos-admin'
import Delivery from '~/services/tesoreria/gestionDelivery'
export default {
    props:{
        pedidos:{
            type:Array,
            default:() => []
        },
        comisiones:{
            type:Array,
            default:() => []
        },
        cajas:{
            type:Array,
            default:() => []
        },
        cuentas:{
            type:Array,
            default:() => []
        },
        tipoCaja:{
            type:Number,
            default:0
        },
        efectivo:{
            type:Boolean,
            default:false
        },
        transferencia:{
            type:Boolean,
            default:false
        },
        credito:{
            type:Boolean,
            default:false
        },
        aplicados:{
            type:Boolean,
            default:false
        },
        descartados:{
            type:Boolean,
            default:false
        },
        sinprocesar:{
            type: Boolean,
            default: false
        },
        selection:{
            type:Boolean,
            default:true
        },
        columCredito:{
            type:Boolean,
            default:true
        },
    },
    data(){
        return {
            seleccionados:[]

        }
    },
    methods:{
        funComisiones(idCedis){
            let comisiones = []
            for (const c of this.comisiones){
                const cedis = c.cedis.split(',')
                if(cedis.includes(String(idCedis))){
                    comisiones.push({id:c.id,nombre:c.condicion})
                }
            }
            return comisiones
        },
        funCajas(idCedis,tipo){ // Tipo => 1 Caja; 2 Cuenta
            let cajas = []
            for (const c of this.cajas){

                const cedis = c.cedis.split(',')
                if(cedis.includes(String(idCedis)) && c.tipo == tipo){
                    cajas.push({id:c.id, nombre:c.nombre})
                }
            }
            return cajas
        },
        async cambioComision(item){
            try {
                console.log(item, 'Este es un ejemplo');
                // return

                const key = item.$index
                const pedido = item.row

                const {data} = await PedidosAdmin.set_condicion(pedido.id, pedido.id_condicion)
                this.notificacion("","Tipo de alianza actualizada correctamente",'success')
                this.setPedido(pedido.id, key)

            } catch (error){
                this.error_catch(error)
            }

        },
        async setPedido(idPedido, key){
            try {

                const {data} = await Delivery.setPedido(idPedido)

                this.pedidos[key].valor_condicion = data.pedido.valor_condicion
                this.pedidos[key].total = data.pedido.total

            } catch (error){
                this.error_catch(error)
            }
        },
        setSeleccion(items){
            this.seleccionados = items
        },
        getSelection(){
            return this.seleccionados
        }
    }
}
</script>
<style  scoped>

</style>
